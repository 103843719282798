import React from 'react';
import styled from 'styled-components';
import GenericGridBlock from 'components/GenericGridBlock';
import TextTile from 'components/tiles/TextTile';
import { resolveUrl } from 'helpers';
import { Industry } from 'src/models/Industry';
import useConnector from './useConnector';

export interface Props {
  title?: string,
  tilesOverride?: Industry[],
}

const IndustriesBlock:React.FC<Props> = ({
  title = 'Industries.',
  tilesOverride,
}) => {
  const industriesData = useConnector();
  const industries = tilesOverride || industriesData;
  return (
    <IndustriesBlockOuter>
      <GenericGridBlock title={title}>
        {industries.map((industry) => (
          <TextTile
            key={industry._id}
            copyHTML={industry.content}
            href={resolveUrl.industry(industry.slug)}
            title={industry.name}
            icon={industry.icon}
          />
        ))}
      </GenericGridBlock>
    </IndustriesBlockOuter>
  );
};
export default IndustriesBlock;

const IndustriesBlockOuter = styled.div``;
