// import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import SmallTile from './SmallTile';
import type { Props as SmallTileType } from './SmallTile';
import { AllowedNumberWithNegatives, AllowedNumber } from '../Spacer/types';

export interface Props {
  title?: string,
  items: SmallTileType[],
  tilePadding?: AllowedNumber,
}

const SmallTileBlock:React.FC<Props> = ({
  title,
  items,
  tilePadding = 1,
}) => {
  if (!items.length) return null;
  return (
    <SmallTileBlockOuter>
      {title && (
        <Spacer pb={4}>
          <Typography variant="hero" as="p">{title}</Typography>
        </Spacer>
      )}
      <SmallTileContainer mx={(tilePadding * -1) as AllowedNumberWithNegatives}>
        {items.map((item) => (
          <TileWrap key={item._id} p={tilePadding}>
            <SmallTile {...item} />
          </TileWrap>
        ))}
      </SmallTileContainer>
    </SmallTileBlockOuter>
  );
};
export default SmallTileBlock;

const SmallTileBlockOuter = styled.div``;
const SmallTileContainer = styled(Spacer)`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
`;
const TileWrap = styled(Spacer)``;
