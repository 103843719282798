import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface Props {
  _id: string,
  title: string,
  image: IGatsbyImageData,
}

const AwardTile:React.FC<Props> = ({
  title,
  image,
}) => {
  // const description = `${name} (${placementType}${year && ` ${year}`})`;
  return (
    <AwardTileOuter>
      <ImageWrap title={title}>
        <GatsbyImage image={image} alt={title} />
      </ImageWrap>
    </AwardTileOuter>
  );
};
export default AwardTile;

const AwardTileOuter = styled.div`
  margin: 0 auto;
  max-width: 150px;
`;

const ImageWrap = styled.div``;
