import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Award } from 'models/Award';

interface RequiredInput {
  [key:string]: any,
  title?: string,
  slug?: string,
  acf?: {
    placementType?: string,
    year?: number,
  },
  featuredImage?: {
    node?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData,
        }
      }
    }
  }
}

export default (
  queryData?: RequiredInput,
): Award | null => {
  if (!queryData) return null;
  return new Award(
    queryData?.title || '',
    queryData?.slug || '',
    queryData?.acf?.year || 0,
    (queryData?.acf?.placementType || 'Nominee') as Award['placementType'],
    queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  );
};
