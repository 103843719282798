import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.FrontPageQuery['wpPage'] => {
  const result = useStaticQuery<GatsbyTypes.FrontPageQuery>(graphql`
    query FrontPage {
      wpPage(isFrontPage: {eq: true}) {
        id
        title
        slug
        content
        acfSeo {
          metaDescription
          metaTitle
          navTitle
        }
        acf {
          copyArea1
          copyArea2
          copyArea3
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
          }
        }
      }
    }
  `);
  return result.wpPage;
};
