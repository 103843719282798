import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Industry } from 'models/Industry';
import { PostlikeWithAcfSeo, PostlikeWithFeaturedImage } from 'src/types';

type RequiredInput = (PostlikeWithFeaturedImage & PostlikeWithAcfSeo & {
  [key:string]: any,
  acf?: {
    icon?: {
      localFile?: {
        childImageSharp?: {
          gatsbyImageData: IGatsbyImageData,
        },
      }
    }
  }
}) | null;

export default (
  queryData?: RequiredInput,
): Industry | null => {
  if (!queryData) return null;
  return new Industry({
    name: queryData.title || '',
    slug: queryData.slug || '',
    content: queryData.content || '',
    metaDescription: queryData.acfSeo?.metaDescription || '',
    metaTitle: queryData.acfSeo?.metaTitle || '',
    navTitle: queryData.acfSeo?.navTitle || '',
    image: queryData.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    icon: queryData.acf?.icon?.localFile?.childImageSharp?.gatsbyImageData,
  });
};
