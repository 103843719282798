import { useStaticQuery, graphql } from 'gatsby';

export const useFiveMostRecentPosts = (): GatsbyTypes.FiveMostRecentPostsQuery['allWpPost']['nodes'] => {
  const res = useStaticQuery<GatsbyTypes.FiveMostRecentPostsQuery>(graphql`
    query FiveMostRecentPosts {
      allWpPost(limit: 5, sort: {fields: date, order: DESC}) {
        nodes {
          ...PostWithThumbnail
        }
      }
    }
  `);
  return res.allWpPost.nodes;
};
