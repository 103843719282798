import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import Block from 'components/Block';
import NavHeader from 'src/components/NavHeader';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Container from 'src/components/Container';
import CtaButton from 'components/CtaButton';
import loadable from '@loadable/component';
import useDynamicTitleVariant from 'src/hooks/useDynamicTitleVariant';

const ParallaxingTypographyBackgroundLazy = loadable(() => import('./ParallaxingTypographyBackground'));

interface Props {
  primaryText: string,
  image?: IGatsbyImageData,
  secondaryTextHtml?: string,
  hideNavHeader?: boolean,
}

const HeroPageHeader:React.FC<Props> = ({
  primaryText,
  image,
  secondaryTextHtml,
  hideNavHeader = false,
}) => {
  const titleArray = primaryText.split(' ');
  const titleLastWord = titleArray.pop();
  const titleFirstWords = titleArray.join(' ');

  const dynamicTitleVariant = useDynamicTitleVariant(primaryText, {
    0: 'pageTitleSmaller',
    38: 'h1',
    50: 'h3',
  });

  return (
    <HeroPageHeaderOuter
      darkBg
      type="noPadding"
    >
      <ContainerAbsolute flexHeight yPadding={0}>
        <Row className="decorative">
          <Col className="left">
            <ParallaxingTypographyBackgroundLazy />
          </Col>
          <Col className="right">
            {image && (
              <ImageWrap>
                <GatsbyImageStyled image={image} alt="Homepage decorative image" />
              </ImageWrap>
            )}
          </Col>
        </Row>
      </ContainerAbsolute>

      {!hideNavHeader && <NavHeader />}
      <Block
        contain
        type="noPadding"
        lineBorders
        flexHeight
      >
        <Row>
          <Col className="left">
            <Relative>
              <Typography as="h1" variant={dynamicTitleVariant}>
                <RegularText>{titleFirstWords}</RegularText>
                <HighLightText> {titleLastWord}</HighLightText>
              </Typography>
            </Relative>
          </Col>
          <Col className="right">
            <Container positionRelativeOuter yPadding={0} xPadding={0} vCenter>
              {/* todo: link text to CMS */}
              <Typography variant="h4" color="white">An insight-driven digital agency with proven results.</Typography>
              <Spacer mb={3} />
              <Typography variant="body">We believe that great work comes from a passion for all things digital and an absolute desire to do more and do it better.</Typography>
              <Spacer mb={3} />
              <div>
                <CtaButton type="primary" variant="underline">Contact Us</CtaButton>
              </div>
            </Container>
          </Col>
        </Row>

      </Block>
    </HeroPageHeaderOuter>
  );
};
export default HeroPageHeader;

const HeroPageHeaderOuter = styled(Block)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 100vh;
  overflow: hidden;
`;
const ContainerAbsolute = styled(Container)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;
const Row = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex-direction: row;
  }
`;
const Col = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex: 1 0 auto;
    padding: 40px;
  }
  ${Row}.decorative & {
    &.left {
      height: 90vh;
      margin-top: 10vh;
      ${({ theme }) => theme.breakpoints.up('tablet')} {
        height: auto;
        margin-top: 0;
      }
    }
  }
  &.left {
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      width: 55%;
    }
    ${({ theme }) => theme.breakpoints.up('tabletWide')} {
      width: 65%;
    }
  }
  &.right {
    ${({ theme }) => theme.breakpoints.up('tablet')} {
      width: 45%;
    }
    ${({ theme }) => theme.breakpoints.up('tabletWide')} {
      width: 35%;
    }
  }
`;
const Relative = styled.div`
  position: relative;
`;

const RegularText = styled.span`
  .dark-bg & { color: white; }
`;
const HighLightText = styled.span``;
const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const GatsbyImageStyled = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  height: calc(100% - 150px);
  bottom: 0;
`;
