/* eslint-disable @typescript-eslint/lines-between-class-members */
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { SitePage, InstanceProps as SitePageInstanceProps } from './SitePage';
import type { Faq } from './Faq';
import type { Client } from './Client';
import type { CaseStudy } from './CaseStudy';
import type { ThoughtLeadershipPiece } from './ThoughtLeadershipPiece';

interface ConstructorProps {
  name: string,
  slug: string,
  metaDescription: string,
  metaTitle?: string,
  navTitle?: string,
  image?: IGatsbyImageData,
  contentHTML?: string,
  excerptHTML?: string,
  copyArea1HTML?: string,
  linkedFaqs?: Faq[],
  linkedCaseStudies?: CaseStudy[],
  linkedClients?: Client[],
  linkedThoughtLeadershipPieces?: ThoughtLeadershipPiece[],
}

type InstanceProps = SitePageInstanceProps & {
  name: string,
  slug: string,
  image?: IGatsbyImageData,
  contentHTML?: string,
  excerptHTML?: string,
  copyArea1HTML?: string,
  linkedFaqs?: Faq[],
  linkedCaseStudies?: CaseStudy[],
  linkedClients?: Client[],
  linkedThoughtLeadershipPieces?: ThoughtLeadershipPiece[],
};


export class Service extends SitePage implements InstanceProps {
  name: string;
  image?: IGatsbyImageData;
  excerptHTML?: string;
  copyArea1HTML?: string;
  linkedFaqs?: Faq[];
  linkedCaseStudies?: CaseStudy[];
  linkedClients?: Client[];
  linkedThoughtLeadershipPieces?: ThoughtLeadershipPiece[];

  constructor(props: ConstructorProps) {
    super({
      title: props.name,
      slug: props.slug,
      contentHtml: props.contentHTML || '',
      metaDescription: props.metaDescription,
      featuredImage: props.image,
      metaTitle: props.metaTitle,
      navTitle: props.navTitle,
    });

    this.name = props.name;
    this.image = props.image;
    this.excerptHTML = props.excerptHTML;
    this.copyArea1HTML = props.copyArea1HTML;
    this.linkedFaqs = props.linkedFaqs;
    this.linkedCaseStudies = props.linkedCaseStudies;
    this.linkedClients = props.linkedClients;
    this.linkedThoughtLeadershipPieces = props.linkedThoughtLeadershipPieces;
  }
}
