import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.CaseStudiesQuery['allWpCaseStudy']['nodes'] => {
  const res = useStaticQuery<GatsbyTypes.CaseStudiesQuery>(graphql`
    fragment CaseStudy on WpCaseStudy {
      id
      slug
      title
      content
      featuredImage {
        ...FeaturedImageThumbnail
      }
    }
    query CaseStudies {
      allWpCaseStudy(sort: {fields: date, order: DESC}) {
        nodes {
          ...CaseStudy
        }
      }
    }
  `);
  return res.allWpCaseStudy.nodes;
};
