import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Base } from './Base';

export type PlacementType = 'Winner' | 'Finalist' | 'Runner Up' | 'Nominee';

export class Award extends Base {
  constructor(
    public name: string,
    public slug: string,
    public year: number,
    public placementType: PlacementType,
    public image?: IGatsbyImageData,
  ) {
    super();
  }
}
