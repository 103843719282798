import { useFiveMostRecentPosts } from 'src/hooks/queries/posts';
import transformer from 'src/transformers/PostQuery_Post';
import isNotNull from 'types/guards/isNotNull';

const useConnector = (): typeof normalizedData => {
  const fiveMostRecentPosts = useFiveMostRecentPosts();
  const normalizedData = fiveMostRecentPosts
    .map((item) => transformer(item))
    .filter(isNotNull);
  return normalizedData;
};

export default useConnector;
