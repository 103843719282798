import React from 'react';
import { Corporation } from 'schema-dts';
import SchemaBase from 'lib/components/schemas/SchemaBase';
import useSiteSettings from 'src/hooks/queries/useSiteSettings';
import useGeneralSettings from 'src/hooks/queries/useGeneralSettings';


interface Props {}


const HomepageSchema:React.FC<Props> = () => {
  const siteSettings = useSiteSettings();
  const generalSettings = useGeneralSettings();
  const schema: Corporation = {
    '@type': 'Corporation',
    name: siteSettings.businessName,
    url: generalSettings.url,
    logo: siteSettings.businessLogo?.localFile?.childImageSharp?.fixed?.src,
    sameAs: [
      'https://twitter.com/blueclaw',
      'https://www.instagram.com/blueclawmedia/',
      'https://www.linkedin.com/company/blueclaw-media-ltd/',
    ],
  };
  return <SchemaBase schema={schema} />;
};
export default HomepageSchema;
