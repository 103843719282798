import dayjs from 'dayjs';

export const format = (
  input: string | Date,
  formatString = 'DD MMMM YYYY',
): string => {
  const date = dayjs(input);
  if (!date.isValid()) {
    console.warn(`invalid date string supplied to format function: "${JSON.stringify(input)}"`);
    return '';
  }
  return date.format(formatString);
};
