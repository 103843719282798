import React from 'react';
import SmallTileBlock from 'components/SmallTileBlock';
import { Props as SmallTileProps } from 'components/SmallTileBlock/SmallTile';
import hasGatsbyImageData from 'types/guards/hasGatsbyImageData';
import { Award } from 'src/models/Award';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';
import Block from 'components/Block';
import Container from 'components/Container';
import useAwards from 'src/hooks/queries/useAwards';
import AwardQuery_Award from 'src/transformers/AwardQuery_Award';
import isNotNull from 'src/types/guards/isNotNull';

export interface Props {
  title?: string,
  overrideAwards?: Award[],
}

const AwardsBlock: React.FC<Props> = ({
  title = 'We build strong relationships, have industry expertise and win awards',
  overrideAwards,
}) => {
  const defaultAwardsRaw = useAwards();
  const data = overrideAwards || Array.from(defaultAwardsRaw, AwardQuery_Award).filter(isNotNull);
  if (!data) return null;

  const getTitle = (award: Award) => `${award.name} (${award.placementType}${award.year && ` ${award.year}`})`;
  const tiles: SmallTileProps[] = data.reduce<SmallTileProps[]>((acc, item) => ([
    ...acc,
    ...(hasGatsbyImageData(item) ? [{
      _id: item._id,
      title: getTitle(item),
      image: item.image,
    }] : []),
  ]), []);

  return (
    <Block type="noPadding" title="Awards.">
      {title && (
        <Container shift xPadding={0} yPadding={0}>
          <Spacer mb={5}>
            <Typography variant="h5" as="p">{title}</Typography>
          </Spacer>
        </Container>
      )}
      <SmallTileBlock
        items={tiles}
        tilePadding={1.5}
      />
    </Block>
  );
};


export default AwardsBlock;
