import React from 'react';
import loadable from '@loadable/component';
import { Props as CarouselProps } from '.';

const CarouselLazy = loadable(() => import('components/Carousel/index'));

const LazyLoader:React.FC<CarouselProps> = (props) => (
  <CarouselLazy {...props} />
);
export default LazyLoader;
