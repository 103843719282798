import React from 'react';
import useCaseStudies from 'hooks/queries/useCaseStudies';
import styled from 'styled-components';
import { Slide } from 'components/Carousel/types';
import GeneralTile from 'components/tiles/GeneralTile';
import Carousel from 'components/Carousel/LazyLoader';
import { resolveUrl } from 'src/helpers';

interface Props {}

const CaseStudyCarousel:React.FC<Props> = () => {
  const caseStudies = useCaseStudies();
  const mapper = (
    item: GatsbyTypes.CaseStudyFragment,
  ): Slide => {
    return {
      id: item.id,
      content: (
        <GeneralTile
          title={item.title || ''}
          image={item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
          href={resolveUrl.caseStudy(item.slug || '')}
        />
      ),
    };
  };
  const slides = caseStudies.map(mapper);
  return (
    <CaseStudyCarouselOuter>
      <Carousel slides={slides} />
    </CaseStudyCarouselOuter>
  );
};
export default CaseStudyCarousel;

const CaseStudyCarouselOuter = styled.div``;
