import { SitePage } from 'models/SitePage';

type RequiredInput = (
  | GatsbyTypes.BasicPageBasicFragment
  | GatsbyTypes.BasicPageWithThumbnailFragment
  | GatsbyTypes.BasicPageWithHeroFragment
) & { featuredImage?: GatsbyTypes.FeaturedImageThumbnailFragment };

export default (
  queryData?: RequiredInput,
): SitePage | null => {
  if (!queryData) return null;
  return new SitePage({
    slug: queryData.slug || '',
    title: queryData.title || '',
    navTitle: queryData?.acfSeo?.navTitle,
    metaTitle: queryData?.acfSeo?.metaTitle,
    contentHtml: queryData.content || '',
    featuredImage: queryData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    metaDescription: queryData.acfSeo?.metaDescription || '',
  });
};
