import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.SiteTextSingleFragment | Record<string, never> => {
  const result = useStaticQuery<GatsbyTypes.SiteTextQuery>(graphql`
    fragment SiteTextSingle on WpSiteSettings_Acfsitetext {
      careersCta
      contactCta
      ctaBlockPrimaryText
      ctaBlockSecondaryText
    }
    query SiteText {
      allWp {
        nodes {
          siteSettings {
            acfSiteText {
              ...SiteTextSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].siteSettings?.acfSiteText || {};
};
