/* eslint-disable @typescript-eslint/lines-between-class-members */
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { SitePage, InstanceProps as SitePageInstanceProps } from './SitePage';

interface ConstructorProps {
  name: string,
  slug: string,
  content: string,
  image?: IGatsbyImageData,
  icon?: IGatsbyImageData,
  metaDescription: string,
  metaTitle: string,
  navTitle: string,
}

type InstanceProps = SitePageInstanceProps & {
  name: string,
  content: string,
  image?: IGatsbyImageData;
  icon?: IGatsbyImageData;
};


export class Industry extends SitePage implements InstanceProps {
  name: string;
  content: string;
  image?: IGatsbyImageData;
  icon?: IGatsbyImageData;

  constructor(props: ConstructorProps) {
    super({
      contentHtml: props.content,
      title: props.name,
      metaDescription: props.metaDescription,
      slug: props.slug,
      metaTitle: props.metaTitle,
      navTitle: props.navTitle,
      featuredImage: props.image,
    });

    this.name = props.name;
    this.content = props.content;
    this.image = props.image;
    this.icon = props.icon;
  }
}
