import { Service } from 'models/Service';
import { AcfSeo, PostlikeWithFeaturedImage } from 'src/types';

type RequiredInput = (PostlikeWithFeaturedImage & {
  [key:string]: any,
  acfSeo?: Partial<AcfSeo>,
  acf?: {
    copyBlock1?: string,
  }
}) | null;

export default (
  queryData?: RequiredInput,
): Service | null => {
  if (!queryData) return null;
  return new Service({
    name: queryData.title || '',
    slug: queryData.slug || '',
    metaDescription: queryData?.acfSeo?.metaDescription || '',
    navTitle: queryData?.acfSeo?.navTitle || '',
    metaTitle: queryData?.acfSeo?.metaTitle || '',
    image: queryData.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    contentHTML: queryData.content,
    excerptHTML: queryData.excerpt,
    copyArea1HTML: queryData.acf?.copyBlock1,
  });
};
