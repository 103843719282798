import React from 'react';
import styled from 'styled-components';
import GenericGridBlock from 'components/GenericGridBlock';
import TextTile from 'components/tiles/TextTile';
import { resolveUrl } from 'helpers';
import { Service } from 'src/models/Service';
// import useConnector from './useAllServices';

export interface Props {
  title?: string,
  services: Service[],
}

const ServicesBlock:React.FC<Props> = ({
  title = 'Our services.',
  services,
}) => (
  <ServicesBlockOuter>
    <GenericGridBlock title={title}>
      {services.map((item) => (
        <TextTile
          key={item._id}
          copyHTML={item.excerptHTML || ''}
          href={resolveUrl.service(item.slug)}
          ctaText="Learn More"
          title={item.name}
          // icon={service.icon}
        />
      ))}
    </GenericGridBlock>
  </ServicesBlockOuter>
);
export default ServicesBlock;

const ServicesBlockOuter = styled.div``;
