import React from 'react';
import styled from 'styled-components';
import TileGrid from 'components/TileGrid';
import BlogTile from 'components/tiles/BlogTile';
import Spacer from 'components/Spacer';
import Typography from 'components/Typography';
import { Post } from 'models/Post';
import { resolveUrl } from 'helpers';

export interface Props {
  tiles: Post[],
  title?: string,
  limit?: number,
}

const PostsWidget:React.FC<Props> = ({
  tiles,
  title = 'Our Posts.',
  limit = 3,
}) => {
  return (
    <PostsWidgetOuter>
      <Spacer pb={4}>
        <Typography variant="hero">{title}</Typography>
      </Spacer>
      <TileGrid>
        {tiles.slice(0, limit).map((post) => (
          <BlogTile
            key={post._id}
            date={post.date}
            title={post.title}
            image={post.featuredImage}
            href={resolveUrl.blog(post.slug)}
          />
        ))}
      </TileGrid>
    </PostsWidgetOuter>
  );
};
export default PostsWidget;

const PostsWidgetOuter = styled.div``;
