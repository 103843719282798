import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.AwardsQuery['allWpAward']['nodes'] => {
  const result = useStaticQuery<GatsbyTypes.AwardsQuery>(graphql`
    query Awards {
      allWpAward(sort: {order: ASC, fields: menuOrder}) {
        nodes {
          ...AwardWithThumbnail
        }
      }
    }
  `);
  return result.allWpAward.nodes;
};
