import React from 'react';
import Component, { Props } from './Component';
import useConnector from './useConnector';

const Default: React.FC<Omit<Props, 'tiles'>> & { tiles?: Props['tiles'] } = (props) => {
  const data = useConnector();
  return <Component {...props} tiles={data} />;
};

export default Default;
export type { Props } from './Component';
