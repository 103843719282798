import React from 'react';
import styled from 'styled-components';
import Typography from 'components/Typography';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { format as formatDate } from 'helpers/date';
import Spacer from 'components/Spacer';

export interface Props {
  title?: string,
  subtitle?: string,
  date?: string,
  href?: string,
  image?: IGatsbyImageData,
  taxonomyLinks?: {
    href: string,
    label: string,
  }[],
}

const GeneralTile:React.FC<Props> = ({
  title,
  subtitle,
  date,
  href,
  taxonomyLinks,
  image,
}) => {
  const formattedDate = date && formatDate(date);
  const ConditionalLink: React.FC = href
    ? ({ children }) => <Link to={href}>{children}</Link>
    : ({ children }) => <>{children}</>;
  return (
    <>
      <GeneralTileOuter>
        {image && (
          <ConditionalLink>
            <Spacer mb={0.5}>
              <GatsbyImage image={image} alt={title || ''} />
            </Spacer>
          </ConditionalLink>
        )}
        {(formattedDate || taxonomyLinks) && (
          <Spacer mb={0.5}>
            <DateBlock variant="smallPrint">
              {formattedDate && <Date as="span">{formattedDate}</Date>}
              {taxonomyLinks?.length && (
                <>
                  {formattedDate && <> • </>}
                  <TaxonomyLinks>
                    {taxonomyLinks.map(({ href: taxonomyHref, label }, i) => (
                      <React.Fragment key={taxonomyHref}>
                        <Link to={taxonomyHref}>{label}</Link>
                        {i < (taxonomyLinks.length - 1) ? ', ' : ''}
                      </React.Fragment>
                    ))}
                  </TaxonomyLinks>
                </>
              )}
            </DateBlock>
          </Spacer>
        )}
        <ConditionalLink>
          {title && (
            <Spacer mt={2} mb={1} multiplier={5}>
              <Typography variant="h6" as="p" className="no-margin">{title}</Typography>
            </Spacer>
          )}
        </ConditionalLink>
        <ConditionalLink>
          {subtitle && <Typography variant="small" className="no-margin">{subtitle}</Typography>}
        </ConditionalLink>
      </GeneralTileOuter>
    </>
  );
};
export default GeneralTile;

const GeneralTileOuter = styled.div`
  a {
    text-decoration: none;
  }
`;

const DateBlock = styled(Typography)``;
const Date = styled(Typography)``;
const TaxonomyLinks = styled.span``;
