import React from 'react';
import SEO from 'lib/components/Seo';
import Container from 'src/components/Container';
import HeroPageHeader from 'components/HeroPageHeader';
import AwardsBlock from 'components/AwardsBlock';
import IndustriesBlock from 'components/IndustriesBlock';
import ServicesBlock from 'components/ServicesBlock';
import PostsWidget from 'src/components/PostsWidget';
import CaseStudyCarousel from 'src/components/carousels/CaseStudyCarousel';
import CtaBlock from 'src/components/CtaBlock';
import Block from 'components/Block';
import styled from 'styled-components';
import useAllServices from 'src/components/ServicesBlock/useAllServices';
import useFrontPage from 'src/hooks/queries/useFrontPage';
import SafeHtml from 'src/lib/components/SafeHtml';
import HomepageSchema from 'components/schemas/HomepageSchema';
import BasicQuery_SitePage from 'src/transformers/BasicPageFragment_SitePage';


interface Props {}

const IndexPage:React.FC<Props> = () => {
  const homepage = useFrontPage();
  const allServices = useAllServices();
  const pageData = BasicQuery_SitePage(homepage);
  if (!pageData) return null;

  return (
    <HomepageOuter>
      <HomepageSchema />
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
      />
      <HeroPageHeader
        primaryText={pageData.h1}
        secondaryTextHtml={homepage?.content || ''}
        image={homepage?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
      />

      <Block contain>
        <CaseStudyCarousel />
      </Block>

      {homepage?.acf?.copyArea1?.trim() && (
        <Block readableWidth contain customConfig={{ py: 0, pt: 0, pb: 3 }}>
          <SafeHtml html={homepage.acf.copyArea1} />
        </Block>
      )}

      <Block highlightBg contain>
        <AwardsBlock />
      </Block>

      <Block contain>
        <IndustriesBlock />
      </Block>

      {homepage?.acf?.copyArea2?.trim() && (
        <Block readableWidth contain>
          <SafeHtml html={homepage.acf.copyArea2} />
        </Block>
      )}

      <Block highlightBg contain>
        <ServicesBlock services={allServices} />
      </Block>

      {homepage?.acf?.copyArea3?.trim() && (
        <Block readableWidth contain>
          <SafeHtml html={homepage.acf.copyArea3} />
        </Block>
      )}

      <Block darkBg>
        <Container yPadding={0}>
          <PostsWidget />
        </Container>
      </Block>

      <CtaBlock />
    </HomepageOuter>
  );
};
export default IndexPage;

const HomepageOuter = styled.div``;
